import React from "react"
import Layout from "../components/layout"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby'

import SEO from "../components/seo"


const postall = ({ data, location }) => {
    return (
        <Layout>
            <SEO
            pagetitle="NEWS"
            pagedesc="MaiPの最新記事一覧です"
            pagepath={location.pathname}
            />
            <section className="content bloglist">
                <div className="container">
                    <h1 className="bar">NEWS</h1>
                    <div className="posts">
                    {
                        data.allMdx.nodes.map((node) => (
                            <article className="post" key={node.slug}>
                            <Link to={`/post/${node.slug}`}>
                                <figure>
                                <GatsbyImage
                                    image={getImage(node.frontmatter.hero_image)}
                                    style={{ height: "100%" }}
                                />
                                </figure>
                                <h3>{node.tableOfContents.items[0].title}</h3>
                            </Link>
                            </article>
                        ))
                    }
                    </div>
                </div>
            </section>
        </Layout>
    )
}


export const query = graphql`
    query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                frontmatter {
                    title
                    summarytxt
                    date(formatString: "MMMM DD, YYYY")
                    hero_image {
                        childImageSharp {
                            gatsbyImageData(width: 700)
                        }
                    }
                }
                slug
                id
                tableOfContents
            }
        }
    }
`

export default postall